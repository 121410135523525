import React, {useState, useEffect} from 'react';
import { Redirect } from 'react-router';
import { MDBDataTableV5 } from 'mdbreact';
import axios from 'axios';
import moment from 'moment';

import Auxi from '../../hoc/Auxi';
import Layout from '../Layout/Layout';
import Spinner from '../../components/Widgets/Spinner';
import Toast from '../../components/Widgets/CustomToast';

import simImage from '../../assets/images/iccid_image.png'


import Navbar from '../Preactivation/Navbar'

import '../../assets/css/preactivation.css';
import '../../assets/css/transfer.css';

const TransferEsim = () =>{

    const [tab, setTab] = useState("email");
    const [email, setEmail] = useState("");
    const [otp, setOtp] = useState("");

    useEffect(() => {
        document.title = 'Upgrade eSIM - Fonus';
    }, []);

    const appLogin = () => {

        let newEmail = email.replaceAll(" ", "")
        setEmail(newEmail)
        const creds = {
            "cloud_id" : "FONUSMOBILE",
            "email" : newEmail,
        };
        axios.post(`${process.env.REACT_APP_API_URL}emailVerification`,creds)
        .then(response =>{ 
            if(response.data !== "OTP Email Sent"){
                let code = "csc:" + newEmail + ":" + response.data + "@" 
                window.location.href = code;
                return;
            }
            setTab("validate")
        })
        .catch(err=>{
            Toast.error("There is no Fonus account with this email address! Error: "+err.response.data);
        
        });
    }

    const otpVerificationHandler= () => {
        let newEmail = email.replaceAll(" ", "")
        const payLoad = {
                "cloud_id" : "FONUSMOBILE",
                "email" : newEmail,
                "otp" : otp
        }
        axios.post(`${process.env.REACT_APP_API_URL}confirmOTPAndTrasnferEsim`,payLoad)
        .then(response =>{ 
            window.location.replace("https://www.fonusmobile.com/esim-upgrade-successful/");
        })
        .catch(err=>{
            
            Toast.error("Invalid code");
        
        });
    }

    return (
        <Auxi>
            <Navbar/>
            {
                tab === "email" ?
                    <div className = "activation-page">
                        <div className="row outer-container">
                            <div className="col-md-12">
                                <h1 className="login-heading">Upgrade your Fonus eSIM</h1>
                                <h4 className="login-subheading"> Log into your Fonus account </h4>
                            </div>
                            <div className="flex" style={{textAlign: 'center'}}>
                                <input className="form-control fonus-input" type="text" name="passcode"
                                    value={email}
                                    placeholder='Email address'
                                    onChange={e => setEmail(e.target.value)}
                                />
                            </div>
                            <div className="flex">
                                <button className="btn btn-primary btn-block fonus-btn" type="button" onClick={() => appLogin()} >Log in</button>
                            </div>
                        </div>
                    </div>
                :
                <div className = "activation-page">
                    <div className="row outer-container">
                        <div className="col-md-12">
                            <h1 className="login-heading"> 
                                {`Please enter the 6-digit code that was \n sent to your email`} 
                            </h1>
                        </div>
                        <div className="flex" style={{textAlign: 'center'}}>
                            <input className="form-control fonus-input" type="text" name="passcode"
                                value={otp}
                                placeholder='6-digit code'
                                onChange={e => setOtp(e.target.value)}
                            />
                        </div>
                        <div className="flex-col">
                            <button className="btn btn-primary btn-block fonus-btn" type="button" onClick={() => otpVerificationHandler()} >Verify code</button>
                            <p className="go-back" onClick={e => setTab("email")}>Go Back</p>
                        </div>
                    </div>
                </div>
            }

        </Auxi>

    );
}

export default TransferEsim;
