import React, {useState, useEffect} from 'react';
import { Redirect } from 'react-router';
import { MDBDataTableV5 } from 'mdbreact';
import axios from 'axios';
import moment from 'moment';

import Auxi from '../../hoc/Auxi';
import Layout from '../Layout/Layout';
import Spinner from '../../components/Widgets/Spinner';
import Toast from '../../components/Widgets/CustomToast';

import simImage from '../../assets/images/iccid_image.png'


import Navbar from '../Preactivation/Navbar'

import '../../assets/css/preactivation.css';
import '../../assets/css/transfer.css';

const Transfer = () =>{

    const [iccid, setIccid] = useState();
    const [tab, setTab] = useState("email");
    const [email, setEmail] = useState("");
    const [otp, setOtp] = useState("");

    useEffect(() => {
        document.title = 'Upgrade SIM - Fonus';
    }, []);

    const appLogin = () => {

        let newEmail = email.replaceAll(" ", "")
        setEmail(newEmail)
        const creds = {
            "cloud_id" : "FONUSMOBILE",
            "email" : newEmail,
        };
        axios.post(`${process.env.REACT_APP_API_URL}emailVerification`,creds)
        .then(response =>{ 
            if(response.data !== "OTP Email Sent"){
                let code = "csc:" + newEmail + ":" + response.data + "@" 
                window.location.href = code;
                return;
            }
            setTab("validate")
        })
        .catch(err=>{
            Toast.error("There is no Fonus account with this email address! Error: "+err.response.data);
        
        });
    }
    const submitHandler= ()=> {
        if (!iccid){
            Toast.error("Please input ICCID")
            return;
        }
        const payLoad = {
            iccid: iccid,
            cloud_username: email,
            cloud_id: "FONUSMOBILE"
        };
        
        axios.post(`${process.env.REACT_APP_API_URL}transferICCID`, payLoad, )
        .then(response => {
            window.location.replace("https://www.fonusmobile.com/upgrade-successful/");
        }).catch(err => {
            Toast.error(err.response.data)
        });
    }

    const otpVerificationHandler= () => {
        let newEmail = email.replaceAll(" ", "")
        const payLoad = {
                "cloud_id" : "FONUSMOBILE",
                "email" : newEmail,
                "otp" : otp
        }
        axios.post(`${process.env.REACT_APP_API_URL}confirmOTP`,payLoad)
        .then(response =>{ 
            setTab("iccid")
            //toast.success("Signing In Please Wait!");
        })
        .catch(err=>{
            
            Toast.error("Invalid code");
        
        });
    }

    return (
        <Auxi>
            <Navbar/>
            {
                tab === "email" ?
                    <div className = "activation-page">
                        <div className="row outer-container">
                            <div className="col-md-12">
                                <h1 className="login-heading">Upgrade your Fonus SIM</h1>
                                <h4 className="login-subheading"> Log into your Fonus account </h4>
                            </div>
                            <div className="flex" style={{textAlign: 'center'}}>
                                <input className="form-control fonus-input" type="text" name="passcode"
                                    value={email}
                                    placeholder='Email address'
                                    onChange={e => setEmail(e.target.value)}
                                />
                            </div>
                            <div className="flex">
                                <button className="btn btn-primary btn-block fonus-btn" type="button" onClick={() => appLogin()} >Log in</button>
                            </div>
                        </div>
                    </div>
                : tab === "validate" ?
                    <div className = "activation-page">
                        <div className="row outer-container">
                            <div className="col-md-12">
                                <h1 className="login-heading"> 
                                    {`Please enter the 6-digit code that was \n sent to your email`} 
                                </h1>
                            </div>
                            <div className="flex" style={{textAlign: 'center'}}>
                                <input className="form-control fonus-input" type="text" name="passcode"
                                    value={otp}
                                    placeholder='6-digit code'
                                    onChange={e => setOtp(e.target.value)}
                                />
                            </div>
                            <div className="flex-col">
                                <button className="btn btn-primary btn-block fonus-btn" type="button" onClick={() => otpVerificationHandler()} >Verify code</button>
                                <p className="go-back" onClick={e => setTab("email")}>Go Back</p>
                            </div>
                        </div>
                    </div>
                : 
                    <div className = "activation-page">
                        <div className="row outer-container">
                            <div className="col-md-12">
                                <h1 className="main-heading"> Activate your Fonus SIM </h1>
                            </div>

                            {/* <div className="container">

                                <div className="item-image">
                                    <img src="https://www.fonusmobile.com/wp-content/uploads/2023/06/E58F4BC1-78D6-403B-85C7-84ECFEF6B0BE-scaled-e1687793035737-1024x583.jpeg.webp"
                                    width="400"/>
                                </div>

                                <div className="item-heading1">
                                    <h2> Please provide SIM info  </h2>
                                </div>

                                <div className="item-heading2">
                                    <h5> Help us identify your fonus SIM</h5>
                                </div>

                                <div className="item-input">
                                    <input type="text"></input>
                                </div>

                                <div className="item-button">
                                    <button>Submit</button>
                                </div>
                            </div> */}

                            <div className="col-md-12 info-container">
                                <div className="row">
                                    <div className="col-md-6 col-12">
                                        <div className="item-image">
                                            <img src={simImage}
                                            width="550"/>
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-12">
                                        <div className="item-heading1">
                                            <p> 
                                                <strong>Enter the ICCID number</strong> located on <br/>the back of your SIM card  
                                            </p>
                                        </div>

                                        <div className="item-input">
                                            <label>SIM card number / ICCID</label>
                                            <input type="text"
                                                onChange={(e) => setIccid(e.target.value)}
                                                name="iccid"
                                                value={iccid}
                                                placeholder='ICCID'/>
                                        </div>

                                        <div className="item-button">
                                            <button onClick={submitHandler}>Activate</button>
                                        </div>

                                        <div className="end-note">
                                            <p>
                                                <i>
                                                    Please note that this activation process is for <b>physical SIMs only.</b>  If you’ve purchased an eSIM already along with one of our plans, you do not need to activate it – the eSIM you received via email is already active.
                                                </i>
                                            </p>
                                        </div>
                                        
                                        
                                    </div>
                                </div>
                            </div>
                        </div>

                </div>
            }
           

        </Auxi>

    );
}

export default Transfer;
